import { FlashcardArray } from "react-quizlet-flashcard";
import { useState, useCallback } from "react";
import "./App.css";

function App() {
  const initialCards = [
    { id: 1, frontHTML: <p>Key A number 1</p>, backHTML: <p>A</p> },
    { id: 2, frontHTML: <p>Key A number 2</p>, backHTML: <p>B Minor</p> },
    { id: 3, frontHTML: <p>Key A number 3</p>, backHTML: <p>C# Minor</p> },
    { id: 4, frontHTML: <p>Key A number 4</p>, backHTML: <p>D</p> },
    { id: 5, frontHTML: <p>Key A number 5</p>, backHTML: <p>E</p> },
    { id: 6, frontHTML: <p>Key A number 6</p>, backHTML: <p>F# Minor</p> },
    {
      id: 7,
      frontHTML: <p>Key A number 7</p>,
      backHTML: <p>G# Diminished</p>,
    },

    { id: 8, frontHTML: <p>Key B number 1</p>, backHTML: <p>B</p> },
    { id: 9, frontHTML: <p>Key B number 2</p>, backHTML: <p>C# Minor</p> },
    { id: 10, frontHTML: <p>Key B number 3</p>, backHTML: <p>D# Minor</p> },
    { id: 11, frontHTML: <p>Key B number 4</p>, backHTML: <p>E</p> },
    { id: 12, frontHTML: <p>Key B number 5</p>, backHTML: <p>F#</p> },
    { id: 13, frontHTML: <p>Key B number 6</p>, backHTML: <p>G# Minor</p> },
    {
      id: 14,
      frontHTML: <p>Key B number 7</p>,
      backHTML: <p>A# Diminished</p>,
    },

    { id: 15, frontHTML: <p>Key C number 1</p>, backHTML: <p>C</p> },
    { id: 16, frontHTML: <p>Key C number 2</p>, backHTML: <p>D Minor</p> },
    { id: 17, frontHTML: <p>Key C number 3</p>, backHTML: <p>E Minor</p> },
    { id: 18, frontHTML: <p>Key C number 4</p>, backHTML: <p>F</p> },
    { id: 19, frontHTML: <p>Key C number 5</p>, backHTML: <p>G</p> },
    { id: 20, frontHTML: <p>Key C number 6</p>, backHTML: <p>A Minor</p> },
    {
      id: 21,
      frontHTML: <p>Key C number 7</p>,
      backHTML: <p>B Diminished</p>,
    },

    { id: 22, frontHTML: <p>Key D number 1</p>, backHTML: <p>D</p> },
    { id: 23, frontHTML: <p>Key D number 2</p>, backHTML: <p>E Minor</p> },
    { id: 24, frontHTML: <p>Key D number 3</p>, backHTML: <p>F# Minor</p> },
    { id: 25, frontHTML: <p>Key D number 4</p>, backHTML: <p>G</p> },
    { id: 26, frontHTML: <p>Key D number 5</p>, backHTML: <p>A</p> },
    { id: 27, frontHTML: <p>Key D number 6</p>, backHTML: <p>B Minor</p> },
    {
      id: 28,
      frontHTML: <p>Key D number 7</p>,
      backHTML: <p>C# Diminished</p>,
    },

    { id: 29, frontHTML: <p>Key E number 1</p>, backHTML: <p>E</p> },
    { id: 30, frontHTML: <p>Key E number 2</p>, backHTML: <p>F# Minor</p> },
    { id: 31, frontHTML: <p>Key E number 3</p>, backHTML: <p>G# Minor</p> },
    { id: 32, frontHTML: <p>Key E number 4</p>, backHTML: <p>A</p> },
    { id: 33, frontHTML: <p>Key E number 5</p>, backHTML: <p>B</p> },
    { id: 34, frontHTML: <p>Key E number 6</p>, backHTML: <p>C# Minor</p> },
    {
      id: 35,
      frontHTML: <p>Key E number 7</p>,
      backHTML: <p>D# Diminished</p>,
    },

    { id: 36, frontHTML: <p>Key F number 1</p>, backHTML: <p>F</p> },
    { id: 37, frontHTML: <p>Key F number 2</p>, backHTML: <p>G Minor</p> },
    { id: 38, frontHTML: <p>Key F number 3</p>, backHTML: <p>A Minor</p> },
    { id: 39, frontHTML: <p>Key F number 4</p>, backHTML: <p>Bb</p> },
    { id: 40, frontHTML: <p>Key F number 5</p>, backHTML: <p>C</p> },
    { id: 41, frontHTML: <p>Key F number 6</p>, backHTML: <p>D Minor</p> },
    {
      id: 42,
      frontHTML: <p>Key F number 7</p>,
      backHTML: <p>E Diminished</p>,
    },

    { id: 43, frontHTML: <p>Key G number 1</p>, backHTML: <p>G</p> },
    { id: 44, frontHTML: <p>Key G number 2</p>, backHTML: <p>A Minor</p> },
    { id: 45, frontHTML: <p>Key G number 3</p>, backHTML: <p>B Minor</p> },
    { id: 46, frontHTML: <p>Key G number 4</p>, backHTML: <p>C</p> },
    { id: 47, frontHTML: <p>Key G number 5</p>, backHTML: <p>D</p> },
    { id: 48, frontHTML: <p>Key G number 6</p>, backHTML: <p>E Minor</p> },
    {
      id: 49,
      frontHTML: <p>Key G number 7</p>,
      backHTML: <p>F# Diminished</p>,
    },
  ];

  const [cards, setCards] = useState(initialCards);

  // Function to shuffle an array
  const shuffleArray = useCallback(() => {
    const shuffled = [...cards].sort(() => Math.random() - 0.5);
    setCards(shuffled);
  }, [cards]);

  return (
    <div className="app-container">
      <h1 className="app-title">Nashville Number System Flashcards</h1>
      <div className="flashcard-container">
        <FlashcardArray
          cards={cards}
          frontCardStyle={{
            backgroundColor: "#ffda79",
            borderRadius: "1rem",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            justifyContent: "center",
            alignItems: "center",
            justifyItems: "center",
            alignItems: "center",
          }}
          backCardStyle={{
            backgroundColor: "#87cefa",
            borderRadius: "1rem",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            justifyContent: "center",
            alignItems: "center",
            justifyItems: "center",
            alignItems: "center",
          }}
          frontContentStyle={{
            fontSize: "1.5rem",
            textAlign: "center",
            justifyContent: "center",
            justifyItems: "center",
            alignItems: "center",

          }}
          backContentStyle={{
            fontSize: "1.5rem",
            textAlign: "center",
            justifyContent: "center",
            justifyItems: "center",
            alignItems: "center",
          }}
          FlashcardArrayStyle={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "20px",
          }}
          controls={true}
          showCount={true}
        />
        <button onClick={shuffleArray} className="shuffle-button">
          Shuffle Cards
        </button>
      </div>
    </div>
  );
}

export default App;
